<template>
  <div class="header-row">
    <filter-group/>
    <b-dropdown
      v-ripple="'rgba(113, 102, 240, 0.15)'"
      class="ml-1 mr-1"
      :text="roleFilter.text"
      variant="primary"
      right
      block
    >
      <b-dropdown-item
        v-for="role in roleOptions"
        :key="role.text"
        @click="roleFilter = role"
      >
        {{ role.text }}
      </b-dropdown-item>
    </b-dropdown>

    <search-bar
      placeholder="이메일/담당자 검색"
    />

  </div>
</template>
<script>

import {
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { inject } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import SearchBar from '@/views/components/table/SearchBar.vue'
import FilterGroup from '@/views/components/table/FilterGroup.vue'

export default {
  components: {
    FilterGroup,
    SearchBar,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  setup() {
    const roleFilter = inject('roleFilter')
    const roleOptions = inject('roleOptions')

    return {
      roleOptions,
      roleFilter,
    }
  },
}
</script>
