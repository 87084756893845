<template>
  <!-- Table Container Card -->
  <div class="table-content">
    <b-table
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      show-empty
      empty-text="일치하는 데이터가 없습니다"
      class="position-relative"
    >
      <template v-slot:empty="scope">
        <div class="my-3 mx-2 text-center">
          {{ scope.emptyText }}
        </div>
      </template>

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ minWidth: '120px' }"
        >
      </template>

      <!-- Column: Email -->
      <template #cell(username)="data">
        <b-link
          style="text-decoration: underline"
          class="font-weight-bold text-nowrap"
          @click="moveTo(data.item)"
        >
          {{ data.value }}
        </b-link>
      </template>

      <!-- Column: name -->
      <template #cell(name)="data">
        <b-link
          style="text-decoration: underline"
          class="font-weight-bold text-nowrap"
          @click="moveTo(data.item)"
        >
          {{ data.value }}
        </b-link>
      </template>

      <!-- Column: Mobile -->
      <template #cell(mobile)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Created Date -->
      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: User Role -->
      <template #cell(roles)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: User Status -->
      <template #cell(status)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

    </b-table>
  </div>
</template>

<script>
import {
  BTable, BLink,
} from 'bootstrap-vue'
import { inject } from '@vue/composition-api'
import router from '@/router'
import { adminRouteName } from '@/router/routes/admin'

export default {
  components: {
    BTable,
    BLink,
  },
  setup() {
    const items = inject('items')
    const tableColumns = inject('tableColumns')
    const currentPage = inject('currentPage')
    const sortBy = inject('sortBy')
    const isSortDirDesc = inject('isSortDirDesc')
    const moveTo = item => {
      router.push({ name: adminRouteName.memberView, params: { memberId: item.id } })
    }
    return {
      moveTo,
      items,
      tableColumns,
      currentPage,
      sortBy,
      isSortDirDesc,
    }
  },
}
</script>
