<template>
  <b-card>
    <loading-component
      v-if="loading"
    />
    <error-component
      v-else-if="error"
      :error="error"
    />
    <app-table-view v-else>
      <template #header>
        <member-list-header />
      </template>
      <template #table>
        <member-list-table />
      </template>
    </app-table-view>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import {
  onMounted, watch,
} from '@vue/composition-api'
import AppTableView from '@/views/layouts/AppTableView.vue'
import MemberListHeader from '../../../components/table/headers/MemberListHeader.vue'
import MemberListTable from '../../../components/table/tables/MemberListTable.vue'
import useMemberList from '../../../utils/member/useMemberList'

export default {
  components: {
    AppTableView,
    BCard,
    MemberListHeader,
    MemberListTable,
  },
  setup() {
    const {
      loading,
      error,
      searchQuery,
      fetchData,
    } = useMemberList()

    watch(searchQuery, () => fetchData())
    onMounted(() => fetchData())

    return {
      loading,
      error,
    }
  },
}
</script>
