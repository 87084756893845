import {
  computed, ref, readonly, provide,
} from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import useDate from '@/views/utils/useDate'

export default function useMemberList() {
  const { t } = useI18nUtils()
  const loading = ref(true)
  const error = ref(null)
  const currentMembers = ref([])
  const currentPage = ref(1)
  const sortBy = ref('openAt')
  const searchQuery = ref('')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const roleFilter = ref({
    text: '전체',
    value: null,
  })
  const { parseDateTable } = useDate()

  const fetchData = async () => {
    loading.value = true
    store.dispatch('admin/fetchMembers', {
      q: searchQuery.value ?? '',
    }).then(response => {
      const { members } = response.data.payload
      currentMembers.value = members
    }).catch(e => {
      error.value = e
    }).finally(() => {
      loading.value = false
    })
  }

  const items = computed(() => currentMembers.value
    .filter(member => member.status === (statusFilter.value || member.status)
      && member.roles === (roleFilter.value.value || member.roles)))

  const statusOptions = computed(() => {
    const activated = currentMembers.value.filter(member => member.status === 'ACTIVATED')
    const disabled = currentMembers.value.filter(member => member.status === 'DISABLED')
    return [
      {
        length: currentMembers.value.length,
        text: `전체 가입자 (${currentMembers.value.length})`,
        value: null,
      },
      {
        length: activated.length,
        text: `활성화 (${activated.length})`,
        value: 'ACTIVATED',
      },
      {
        length: disabled.length,
        text: `비활성화 (${disabled.length})`,
        value: 'DISABLED',
      },
    ]
  })

  const roleOptions = [
    {
      text: '전체',
      value: null,
    },
    {
      text: '회원',
      value: 'ROLE_USER',
    },
    {
      text: '관리자',
      value: 'ROLE_ADMIN',
    },
  ]

  // Table Handlers
  const tableColumns = computed(() => [
    { key: 'username', label: t('Email') },
    { key: 'name', label: t('Name') },
    { key: 'mobile', label: t('Mobile') },
    { key: 'createdAt', label: t('CreatedAt'), formatter: val => parseDateTable(val) },
    { key: 'roles', label: t('UserRole'), formatter: val => t(val) },
    { key: 'status', label: t('UserStatus'), formatter: val => t(`USER_${val}`) },
  ])

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  provide('statusOptions', readonly(statusOptions))
  provide('roleOptions', readonly(roleOptions))
  provide('roleFilter', roleFilter)
  provide('statusFilter', statusFilter)
  provide('searchQuery', searchQuery)

  // for SlotViewTable & Footer
  provide('items', readonly(items))
  provide('tableColumns', readonly(tableColumns))
  provide('sortBy', readonly(sortBy))
  provide('isSortDirDesc', readonly(isSortDirDesc))
  provide('currentPage', currentPage)

  // common use for fetching data
  provide('fetchData', fetchData)

  return {
    loading,
    error,
    searchQuery,
    fetchData,
  }
}
