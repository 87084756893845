<template>
  <div class="filter-group">
    <b-form-radio-group
      v-model="filter"
      :options="options"
      buttons
      button-variant="outline-primary"
    />
  </div>
</template>
<script>

import { BFormRadioGroup } from 'bootstrap-vue'
import { inject } from '@vue/composition-api'

export default {
  components: {
    BFormRadioGroup,
  },
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const options = inject('statusOptions')
    const filter = inject('statusFilter')

    return {
      options,
      filter,
    }
  },
}
</script>
